<template>
  <div class="table-wrap v_padding_medium">
    <div class="table-wrap-bg"></div>
    <div class="thead-desktop table-item">
      <div class="table-item-list">
        <div
          v-for="(dataRow, index) in tableData"
          :key="index"
          class="table-item-list-data"
        >
          <div class="text_small">
            {{ dataRow[0] }}
          </div>
        </div>
      </div>
    </div>
    <div class="table-item" v-for="(head, headIndex) in tableHead" :key="head">
      <div class="table-item-head text_small">
        {{ head }}
      </div>
      <div class="table-item-list">
        <div
          v-for="(dataRow, index) in tableData"
          :key="index"
          class="table-item-list-data"
        >
          <div class="text_small thead-mobile">
            {{ dataRow[0] }}
          </div>
          <div class="text_small">
            {{ dataRow[headIndex + 1] }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "uiTableBg",
  props: {
    tableHead: {
      type: Array,
      required: true,
    },
    tableData: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../vars";
.table-wrap {
  background-image: url("/armor/table_bg.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  .table-wrap-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .table-item {
    margin-bottom: 40px;
    z-index: 10;
  }
  .table-item-head {
    font-weight: bold;
    text-align: center;
    padding: 30px;
  }
  .thead-desktop {
    display: none;
  }
  .table-item-list {
    display: flex;
    flex-direction: column;
    .table-item-list-data {
      margin: 0 40px;
      padding: 10px 0;
      border-bottom: 1px solid #323232;
      display: flex;
      flex-direction: row;
      align-items: center;
      div {
        flex: 0 1 50%;
        text-align: center;
      }
    }
  }
}

@supports (-webkit-touch-callout: none) {
  .table-wrap {
    background-attachment: unset;
  }
}
@media (min-width: $large_w) {
  .table-wrap {
    display: flex;
    flex-direction: row;
    padding: 80px;
    justify-content: center;
    align-items: end;
    .table-item {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 500px;
    }
    .table-item-list {
      .table-item-list-data {
        display: block;
        margin: 0;
      }
    }

    .thead-mobile {
      display: none;
    }
    .thead-desktop {
      align-items: end;
      display: flex;
      .table-item-list {
        width: 100%;
      }
    }
  }
}
</style>
