<template>
  <div class="image-guns-props">
    <div
      class="image-guns-item b_margin_medium"
      v-for="(item, index) in items"
      :key="index"
    >
      <img :src="item.src" alt="" />
      <div class="image-guns-item-list">
        <span class="v_padding_esmall text_small"> {{ $t(item.name) }} </span>
        <div
          class="image-guns-item-list-item"
          v-for="(props, propsIndex) in item.bullet"
          :key="propsIndex"
        >
          <img src="/armor/list-circle.svg" alt="" />
          <span class="text_small"> {{ $t(props) }} </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "uiImageGunsList",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.image-guns-props {
  display: flex;
  flex-direction: column;
  .image-guns-item {
    img {
      width: 100%;
    }
    .image-guns-item-list {
      span {
        font-weight: bold;
        display: block;
      }
      .image-guns-item-list-item {
        display: flex;
        flex-direction: row;
        img {
          width: 20px;
        }
        span {
          font-weight: normal;
          padding: 10px;
        }
      }
    }
  }
}
</style>
