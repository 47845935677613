<template>
  <div class="container">
    <uiTopPageSection
      :src="backgroundImage"
      :bgPosition="{ x: '27%', y: '60%' }"
    >
      <div class="top-content-section">
        <p class="v_margin_small text_small">
          {{ $t("armor.topPage.p1") }}
        </p>
        <h1 class="text_super_big">DEMIDOV ARMOR</h1>
        <p class="v_margin_small text_small">
          {{ $t("armor.topPage.p2") }}
        </p>
      </div>
    </uiTopPageSection>
    <section class="armor-technology section max_with">
      <h2
        v-scrollanimate="'bottom'"
        class="text_small text_center text_bold v_margin_small"
      >
        {{ $t("armor.section1.p1") }}
      </h2>
      <p
        v-scrollanimate="'bottom'"
        class="text_small text_center v_margin_small"
      >
        {{ $t("armor.section1.p2") }}
      </p>
      <div class="armor-technology-requirements">
        <div class="armor-technology-item b_margin_medium">
          <p class="text_small text_bold">{{ $t("armor.section1.p3") }}</p>
          <img src="/armor/requirements1.jpg" alt="" />
          <div class="armor-technology-item-list">
            <p class="text_small">
              {{ $t("armor.section1.p4") }}
            </p>
            <p class="text_small">{{ $t("armor.section1.p5") }}</p>
          </div>
        </div>
        <div class="armor-technology-item">
          <p class="text_small text_bold">{{ $t("armor.section1.p6") }}</p>
          <img src="/armor/requirements2.jpg" alt="" />
          <div class="armor-technology-item-list">
            <p class="text_small">{{ $t("armor.section1.p7") }}</p>
            <p class="text_small">{{ $t("armor.section1.p8") }}</p>
          </div>
        </div>
      </div>
      <div class="video-link">
        <router-link
                tag="a"
                exact="true"
                class="video-link__href"
                :to="'/' + this.$i18n.locale + '/video_2'"
        >{{$t("messages.video")}} <font-awesome-icon :icon="['fab', 'youtube']" /></router-link>
      </div>
    </section>
    <section class="armor-concept section light">
      <div class="armor-concept-item vueper_slider">
        <vueper-slides
          ref="vueperslides1"
          :touchable="true"
          :autoplay="false"
          :bullets="false"
          :dragging-distance="70"
          prevent-y-scroll
          class="vueper_slide"
          @slide="
            $refs.vueperslides2.goToSlide($event.currentSlide.index, {
              emit: false,
            })
          "
        >
          <vueper-slide
            v-for="(slide, i) in slides"
            :key="i"
            :image="slide.image"
          >
          </vueper-slide>
        </vueper-slides>
        <vueper-slides
          class="no-shadow thumbnails slider-thumbs vueper_slide_small"
          ref="vueperslides2"
          @slide="
            $refs.vueperslides1.goToSlide($event.currentSlide.index, {
              emit: false,
            })
          "
          :visible-slides="slides.length"
          :bullets="false"
          :touchable="false"
          :gap="2.5"
          :arrows="false"
        >
          <vueper-slide
            v-for="(slide, i) in slides"
            :key="i"
            :image="slide.image"
            @click="$refs.vueperslides2.goToSlide(i)"
          >
          </vueper-slide>
        </vueper-slides>
      </div>
      <div class="armor-concept-item">
        <h2
          v-scrollanimate="'bottom'"
          class="text_medium text_bold b_margin_medium"
        >
          <span class="text_uppercase">DEMIDOV ARMOR</span>
          {{ $t("armor.section2.p1") }}
        </h2>
        <p v-scrollanimate="'bottom'" class="text_small">
          {{ $t("armor.section2.p2") }}
        </p>
        <ui-targets-list :items="targetsList" />
      </div>
    </section>
    <section class="armor-video">
      <video autoplay muted loop>
        <source :src="getVideo()" type="video/mp4" />
        <source :src="getVideo(1)" type="video/webm" />
      </video>
    </section>
    <section class="armor-ballistic section max_with">
      <div class="armor-ballistic__header">
        <p class="text_bold text_medium text_center b_margin_small">
          {{ $t("armor.section3.p1") }}
        </p>
        <h2 class="text_small text_center">
          {{ $t("armor.section3.p2") }}
        </h2>
        <div class="video-link b_margin_medium">
          <router-link
                  tag="a"
                  exact="true"
                  class="video-link__href"
                  :to="'/' + this.$i18n.locale + '/video_3'"
          >{{$t("messages.video")}} <font-awesome-icon :icon="['fab', 'youtube']" /></router-link>
        </div>
      </div>
      <ui-image-guns-list :items="guns" />
    </section>
    <section class="armor-table">
      <ui-compare-table :table-head="tableHead" :table-data="tableData" />
    </section>
    <section class="armor-best v_padding_medium">
      <div class="padding_medium">
        <p
          class="
            text_center text_bold text_uppercase text_medium
            b_margin_small
          "
        >
          DEMIDOV ARMOR
        </p>
        <h2 class="text_center text_small text_italic">
          {{ $t("armor.section5.p1") }}
        </h2>
      </div>
      <div class="armor-best__items">
        <div class="armor-best__item">
          <div
            class="armor-best__item_image"
            style="background-image: url('/armor/fits.jpg')"
          ></div>
          <p class="text_small text_center text_bold v_margin_small">
            {{ $t("armor.section5.p2") }}
          </p>
        </div>
        <div class="armor-best__item">
          <div
            class="armor-best__item_image"
            style="background-image: url('/armor/hidden.jpg')"
          ></div>
          <p class="text_small text_center text_bold v_margin_small">
            {{ $t("armor.section5.p3") }}
          </p>
        </div>
        <div class="armor-best__item">
          <div
            class="armor-best__item_image"
            style="background-image: url('/armor/ballistic.jpg')"
          ></div>
          <p class="text_small text_center text_bold v_margin_small">
            {{ $t("armor.section5.p4") }}
          </p>
        </div>
      </div>
      <div class="armor-quoted margin_small">
        <img src="/armor/quotes.svg" alt="" />
        <p class="text_small h_padding_small">
          <span v-scrollanimate="'left'" class="text_bold">{{
            $t("armor.section5.p5")
          }}</span>
          {{ $t("armor.section5.p6") }}
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import backgroundImage from "@/assets/armor/armor_bg_image.jpg";
import uiCompareTable from "../components/UI/uiCompareTable";
import uiTargetsList from "../components/UI/uiTargetsList";
import uiImageGunsList from "../components/UI/uiImageGunsList";
import uiTopPageSection from "../components/UI/uiTopPageSection";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import backgroundVideo from "@/assets/armor/videobg.mp4";
import backgroundVideo1 from "@/assets/armor/videobg.webm";
export default {
  name: "Armor",
  components: {
    VueperSlides,
    VueperSlide,
    uiCompareTable,
    uiTargetsList,
    uiImageGunsList,
    uiTopPageSection,
  },
  data() {
    return {
      backgroundImage,
      slides: [
        { image: "/armor/slider/armor1.jpg" },
        { image: "/armor/slider/armor2.jpg" },
        { image: "/armor/slider/armor3.jpg" },
        { image: "/armor/slider/armor4.jpg" },
        { image: "/armor/slider/armor5.jpg" },
        { image: "/armor/slider/armor6.jpg" },
        { image: "/armor/slider/armor7.jpg" },
        { image: "/armor/slider/armor8.jpg" },
        { image: "/armor/slider/armor9.jpg" },
        { image: "/armor/slider/armor10.jpg" },
      ],

      tableHead: [
        this.$i18n.t("armor.section4.tableHead[0]"),
        "DEMIDOV ARMOR",
      ],
      tableData: [
        ["Magnum 44", "29 mm", "4 mm"],
        ["TT", "14 mm", "2 mm"],
        ["Glok 17", "13 mm", "1 mm"],
        ["Shotgun", "40 mm", "15 mm"],
      ],
      targetsList: [
        "armor.section2.targetsList[0]",
        "armor.section2.targetsList[1]",
        "armor.section2.targetsList[2]",
        "armor.section2.targetsList[3]",
        "armor.section2.targetsList[4]",
      ],
      guns: [
        {
          src: "/armor/magnum.jpg",
          name: "armor.section3.guns[0].name",
          bullet: [
            "armor.section3.guns[0].bullet[0]",
            "armor.section3.guns[0].bullet[1]",
            "armor.section3.guns[0].bullet[2]",
          ],
        },
        {
          src: "/armor/TT.jpg",
          name: "armor.section3.guns[1].name",
          bullet: [
            "armor.section3.guns[1].bullet[0]",
            "armor.section3.guns[1].bullet[1]",
           "armor.section3.guns[1].bullet[2]",
          ],
        },
        {
          src: "/armor/glok.jpg",
          name: "armor.section3.guns[2].name",
          bullet: [
            "armor.section3.guns[2].bullet[0]",
            "armor.section3.guns[2].bullet[1]",
            "armor.section3.guns[2].bullet[2]",
          ],
        },
        {
          src: "/armor/shotgun.jpg",
          name: "armor.section3.guns[3].name",
          bullet: [
            "armor.section3.guns[3].bullet[0]",
            "armor.section3.guns[3].bullet[1]",
            "armor.section3.guns[3].bullet[2]",
          ],
        },
      ],
    };
  },
  methods: {
    getVideo(num) {
      if (num === 1) return backgroundVideo1;
      return backgroundVideo;
    },
  },
};
</script>
<style lang="scss">
@import "../vars";
.armor-technology {
  .armor-technology-item {
    img {
      width: 100%;
      height: auto;
    }
  }
  .armor-technology-requirements {
    display: flex;
    flex-direction: column;
  }
}
.armor-concept {
  .armor-concept--list-item {
    display: flex;
    img {
      height: 25px;
      margin-right: 10px;
    }
  }
  .vueper_slide {
    .vueperslides__parallax-wrapper {
      height: 300px;
    }
  }
  .vueper_slide_small {
    .vueperslides__parallax-wrapper,
    .vueperslides__track {
      height: 40px;
    }
  }
}
.armor-ballistic {
}
.armor-best {
  .armor-best__items {
    display: flex;
    flex-direction: column;
    .armor-best__item_image {
      background-size: cover;
      background-position: center;
      height: 250px;
    }
  }
  .armor-quoted {
    border-left: 1px solid gray;
    display: flex;
    flex-direction: column;
    img {
      width: 40px;
    }
  }
}

button.vueperslides__arrow {
  color: #ffbe4f;
}
.thumbnails .vueperslide {
  box-sizing: border-box;
  border: 1px solid #fff;
  transition: 0.3s ease-in-out;
  opacity: 0.7;
  cursor: pointer;
}
.thumbnails .vueperslide--active {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  opacity: 1;
}
.slider-thumbs {
  margin-top: 10px;
}
.armor-video {
  height: 500px;
  overflow: hidden;
  display: none;
  align-items: center;
  video {
    width: 100%;
  }
}

@media (min-width: $small_w) {
  .armor-concept {
    .vueper_slide {
      .vueperslides__parallax-wrapper {
        height: 400px;
      }
    }
    .vueper_slide_small {
      .vueperslides__parallax-wrapper,
      .vueperslides__track {
        height: 60px;
      }
    }
  }
}
@media (min-width: $medium_w) {
  .armor-best .armor-best__items .armor-best__item_image {
    height: 400px;
  }
  .armor-concept {
    .vueper_slide {
      .vueperslides__parallax-wrapper {
        height: 500px;
      }
    }
    .vueper_slider {
      width: 500px;
      margin: auto;
    }
    .vueper_slide_small {
      .vueperslides__parallax-wrapper,
      .vueperslides__track {
        height: 60px;
      }
    }
  }
}
@media (min-width: $large_w) {
  .armor-technology {
    margin: auto;
    .armor-technology-requirements {
      flex-direction: row;
      .armor-technology-item {
        padding: 15px;
        img {
          margin: 25px 0;
        }
      }
    }
  }
  .armor-concept {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .armor-concept-item {
      max-width: 800px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-basis: 0;
      padding: 15px;
    }
  }
  .armor-ballistic {
    .image-guns-props {
      display: flex;
      flex-direction: row;
      .image-guns-item {
        flex-grow: 1;
        flex-basis: 0;
        padding: 15px;
      }
    }
  }
  .armor-best {
    max-width: 2500px;
    margin: auto;
    .armor-best__items {
      display: flex;
      flex-direction: row;
      gap: 15px;
      .armor-best__item {
        flex-grow: 1;
        flex-basis: 0;
      }
    }
  }
  .armor-video {
    display: flex;
  }
  .armor-concept {
    .vueper_slide {
      .vueperslides__parallax-wrapper {
        height: 550px;
      }
    }
    .vueper_slider {
      max-width: 550px;
      width: 550px;
      margin: 0;
    }
    .vueper_slide_small {
      .vueperslides__parallax-wrapper,
      .vueperslides__track {
        height: 60px;
      }
    }
  }
}
</style>
